















































import OfflineTransferTransactionTs from './OfflineTransferTransactionTs';
export default class OfflineTransferTransaction extends OfflineTransferTransactionTs {}
