

































import { FormOfflineTransferTransactionTs } from './FormOfflineTransferTransactionTs';
export default class FormOfflineTransferTransaction extends FormOfflineTransferTransactionTs {}
